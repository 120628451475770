.sidenav {
    float: left;
    width: 240px;
    position: absolute;
    top: 70px; 
    bottom: 0;
    height: calc(100vh - 70px);
    background-color: var(--fz-green-1);
    padding-top: 0px;
    transition: width 0.3s ease-in-out;
    overflow-y: auto;
}
.sidenavClosed {
    composes: sidenav;
    transition: width 0.3s ease-in-out;
    width: 55px;
}

.sideitem {
    display: flex;
    align-items: center;
    padding: 15px 15px;
    cursor: pointer;
    color: #2B3B36;
    text-decoration: none;
    overflow: hidden;
    background-color: #889893;
}
.sideitem:hover {
    /* background-color: rgb(97, 131, 121); */
    color: #3e3f3f;
    background-color: #788682;
}

.linkText {
    padding-left: 16px;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Use ellipses for overflowed text */    
}

.menuBtn {
  display: flex;
  align-items: center;
  padding: 15px 15px;
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;
  background-color: #889893;
}
.menuBtn:hover {
  color: #3e3f3f;
  background-color: #788682;
}

.poweredBy {
  float: left;
  position: absolute;
  bottom: 0px;
  color: var(--fz-gray-3);
  background-color: #d9d9d950 !important;
  padding: 3px 6px;
  border-radius: 3px;
  z-index: 99;
}
.poweredBy:hover {
  float: left;
  position: absolute;
  bottom: 0px;
  color: var(--fz-gray-1);
  background-color: #d9d9d990 !important;
  padding: 3px 6px;
  border-radius: 3px;
  z-index: 99;
  cursor: default;
}