.root {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  height: 32px;
  padding-inline-end: 8px;
  border-bottom: solid 1px #eee;
}

.root.isSelected {
  color: #3a9c6c;
  background-color: #f3f3f3;
}

/* mod: 2023-12-15 */
ul {
  padding: 0px !important;
}

.expandIconWrapper {
  align-items: center;
  font-size: 0;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transition: transform linear 0.1s;
  transform: rotate(0deg);
}

.expandIconWrapper.isOpen {
  transform: rotate(90deg);
}

.labelGridItem {
  padding-inline-start: 8px;
}

.inputWrapper {
align-items: center;
display: grid;
grid-template-columns: repeat(3, auto);
justify-content: flex-start;
}

.textField input {
font-size: 14px;
padding: 6px 0;
}

.editButton {
padding: 6px;
}

.editIcon {
font-size: 20px;
padding: 2px;
color: #bababa;
}

.deleteIcon {
font-size: 20px;
color: #bababa;
}

.nodeInput {
width: 200px;
}

.nodeLabel {
padding-right: 15px;
}
