footer {
  position: fixed;
  bottom: 5%;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
}

/* ------------------------------ */
/* new mod */
/* ------------------------------ */
.title-bar {
  background-color: var(--fz-green-1);
  padding: 15px !important;
  color: #fff;
  height: 70px; /* Adjust height */
  text-align: center;
  vertical-align: middle;
}

.navigation {
  background-color: #e4e1e1;
  overflow-x: hidden;
  overflow-y: auto;
  width: 250px;
  height: calc(100vh - 70px) !important;
  transition: width 0.3s ease-in-out;
  position: absolute;
  top: 70px;
  bottom: 0;
}

.navigation ul {
  list-style-type: none;
  padding: 0;
}

.navigation li {
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navigation.collapsed {
  width: 60px; /* Collapsed width */
  transition: width 0.3s ease-in-out;
}

.toggle-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.main-div {
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100% !important;
  height: calc(100vh - 70px);
  transition: width 0.3s ease-in-out;
  padding-top: 10px;
}
.main-div.expanded {
  background-color: #fafafa;
}

.main-content {
  background-color: #fafafa;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100% !important;
  height: calc(100vh - 70px);
  margin-left: 55px !important;
  padding-left: 197px !important;
  min-width: calc(100vw - 55px);
  transition: padding 0.3s ease-in-out;
}
.main-content.expanded {
  margin-left: 55px !important;
  padding-left: 12px !important;
  max-width: calc(100vw - 55px);
  transition: padding 0.3s ease-in-out;
}


.page-content {
  padding: 20px;
  /* background-color: #fafafa; */
  overflow-x: hidden;
  overflow-y: auto;
  /* background-color: yellowgreen; */
}
/* .page-content::-webkit-scrollbar {
  background-color: red;
  width: 20px;
  display: block;
} */

/* ::-webkit-scrollbar {
  display: none;
} */


.col-span-2 {
  column-span: 2;
}

.message-count .badge {
  position: relative;
  top: -5px;
  right: -5px;
  padding: 5px 8px 5px 6px;
  border-radius: 6px;
  background: rgb(204, 0, 0);
  color: white;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

/* ------------------------------ */

.data-area-div {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* border: 40px solid #ff0000; */
}

.navbarButton {
  color: #fff !important;
  padding: .5rem 1rem !important;
}

.navbarStyle {
  padding: .5rem 1rem !important;
  background-color: #204673 !important;
}

.table-responsive {
  max-height: 39rem !important;
}

.tableColumn {
  word-break: break-all
}

.todo-form {
  width: 80%;
}
.todo-list {
  width: 100%;
  background-color: red;
  cursor: pointer;
}
.todo-view-btn {
  float: right;
}

.list-label {
  font-size: large;
  /* margin-top: 10px; */
  cursor: pointer;
}

.request-props {
  /* background-color: greenyellow; */
  /* padding: 0px; */
  /* margin: -15px -5px -10px -10px !important; */
  /* border: 10px solid #ff0000 !important; */
  padding: 0;
  border-collapse: separate;
}

.table-header {
  margin: 0 !important;
  padding: 0 !important;
  /* border: 0 !important; */
  border:none !important;
  outline: 0 !important;
  font-size: 100% !important;
  vertical-align: baseline !important;
  background: transparent !important;
  display: none;
}

.status-assigned {
  /* background-color: orange !important; */
  /* padding: 0px 2px 2px 2px; */
  margin:  -4px 0px -2px 0px;
  padding: 3px;
  border-radius: 3px;
  min-width: 150px;
  max-width: 150px;
  max-height: 40px;
  text-align: center;
  vertical-align: text-top;
  float: right;
}


.iconText {
  margin: 0 .5rem;
}

.profileButton {
  margin: .5rem .5rem;
}
/* ------------------ */

.app-card {
  width: 18rem !important;
  height: 12rem !important;
  margin: 10px !important;
}
.app-card:hover {
  cursor: pointer;
  background-color: #e1ebe7;
}

.app-icon {
  margin: 15px 15px 0px 15px !important;
  font-size: 36px !important;
}

.popup-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.popup-content {
  background-color: white;
  padding: 25px;
  border-radius: 6px;
  font-size: medium;
}

/* ------------------ */

.custom-border-1 {
  border: solid #DEE2E6;
  border-width: 0 1px 0 0 !important;
}

/* ------------------ */

.branding-name {
  font-size: larger;
  padding-left: 10px;
  vertical-align: middle;
}
.branding-logo {
  height: 45px;
  padding: 5px;
  margin-left: 5px;
}

.list-group-item {
  padding-top: 5px !important;
  padding-bottom: 10px !important;
  cursor: pointer;
}
.list-group-item:hover {
  background-color: #e8f3ef !important;
}

.td-center-h {
  text-align: center !important;
  text-align-last: center;
  -moz-text-align-last: center;
}
.td-top-v {
  vertical-align: top !important;
}
.td-center-v {
  vertical-align: middle !important;
}
.td-bottom-v {
  vertical-align: bottom !important;
}
.td-left-h {
  /* width: 100px; */
  text-align: left !important;
}
.td-right-h {
  width: 100px;
  text-align: right !important;
  text-align-last: right;
  -moz-text-align-last: right;
}

/* ------------------ */

.no-borders {
  border-width: 0;
}

.dropdown-toggle {
  height: 38px;
  text-align: left !important;
}
.dropdown-toggle::after {
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 18px;
}

.settings-button {
  cursor: pointer;
  font-size: 30px !important;
  margin-top: -4px;
  color: var(--fz-green-3);
}

.back-button {
  cursor: pointer;
  font-size: 35px !important;
  color: var(--fz-green-menu);
}
.back-button:hover {
  cursor: pointer;
  font-size: 35px !important;
  color: var(--fz-green-3);
}

.landing-page {
  background-image: url("https://finzen.io/images/bg_mountains.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 95vh;
  margin-left: 0px;
  opacity: 0.6;
}
/* .landing-page::-webkit-scrollbar {
  background-color: red;
  width: 20px;
  display: block;
} */

.welcome-message1 {
  float: left;
  position: calc(100vw / 2);
  /* top: calc(50vh / 2); */
  /* left: calc(70vw / 2); */
  padding: 50px 150px 50px 150px;
  margin: 50px;
  background-color: #dae2e4;
  color: #000000;
  border-radius: 10px;
  opacity: 0.9 !important;
}

.welcome-message {
  margin: auto;
  width: 350px;
  top: 50%;
  transform: translate(0, 50%);
  padding: 20px;
  background-color: #dae2e4;
  color: #000000;
  border-radius: 10px;
  opacity: 0.9 !important;
}

/* ------------------ */

/* Style the entire grid container */
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Set two columns */
  grid-gap: 10px;
  width: 100%;
  height: calc(50vh - 100px) ;
  margin: 0 auto; /* Center the container horizontally (optional) */
  line-height: 20px; 

}

/* Style each grid item */
.grid-item {
  background-color: #f0f0f0; /* Set a light gray background */
  border: 1px solid #ddd; /* Add a thin border */
  border-radius: 3px;
  text-align: center; /* Center any text within the grid item */
  line-height: 20px; 

  min-width: calc(100%) !important;

  /* width: calc(100%) !important; */
  /* min-width: calc(100%) !important; */
  /* max-width: calc(100%) !important; */

  /* height: calc(100% - 10px) !important; */
  /* min-height: calc(100%) !important; */
  /* max-height: calc(100%) !important; */

  box-sizing: border-box;
  width: calc(100%);
  padding: 5px 10px 0px 10px; 
}

/* Adjust styles for narrow screens */
@media only screen and (max-width: 600px) {
  /* Change grid layout to one column when screen is narrow */
  .grid-container {
    grid-template-columns: 1fr;
    width: 100%;
    height: calc(100vh - 20px) ;      
  }
  .grid-item {
    font-size: medium;
    grid-template-columns: 1fr;
  }
}


.grid-container-2 {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Set two columns */
  grid-gap: 10px;
  width: 100%;
  height: calc(50vh - 60px) ;
  margin: 0 auto; /* Center the container horizontally (optional) */
  line-height: 20px; 
}


.row-style-1 {
  /* background-color: yellowgreen;
  height: calc(50vh - 40px); */
}
.row-style-2 {
  /* background-color: plum;
  height: calc(50vh - 86px); */
}
.col-style-1 {
  /* background-color: pink !important; */
  text-align: center;
  margin: 5px;
  padding: 5px;

  display: flex;
  flex-direction: column;
  height: calc(50vh - 40px);
}
.col-style-2 {
  /* background-color: plum !important; */
  text-align: center;
  margin: 5px;
  padding: 5px;

  display: flex;
  flex-direction: column;
  height: calc(50vh - 40px);
}
.col-style-3 {
  /* background-color: yellowgreen !important; */
  text-align: center;
  margin: 5px;
  padding: 5px;

  display: flex;
  flex-direction: column;
  height: calc(50vh - 106px);
}


.col-border-1 {
  border: solid var(--fz-gray-6);
  border-width: 3px 3px 3px 3px !important;
  border-radius: 6px;
}
/* ------------------ */

.svc-req-page {
  height: calc(100vh - 90px);
  /* background-color: #eedddd; */
  /* overflow-y: hidden; */
}

.svc-req-list-head {
  /* 889893 cbd8d3 */
  background-color: #e2e2e2;   
  border-radius: 5px;
}
.svc-req-list-body {
  background-color: #f3f3f3; 
  height: calc(40vh - 100px) !important;
}

.svc-req-scroll {
  height: calc(50vh - 125px);
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.custom-ht-1 {
  height: calc(100vh - 200px);
}
.custom-ht-2 {
  height: calc(50vh - 125px);
}

/* ------------------ */

.form-select {
  height: 100%;
}

.form-row {
  padding: 10px;
}

.form-scroll-area {
  height: calc(100vh - 220px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -10px;
  padding-top: 10px;
  padding-right: 10px;
  background-color: #f5f5f5 !important;
}

.status-label {
  padding-top: 2px;
  font-size: large;
}

/* ------------------ */

.message-area {
  background-color: #bec9c3;
  /* min-height: 100%;
  height: 100% !important; */
}
.message-scroll-area {
  height: calc(100vh - 170px);
  overflow-y: scroll;
  overflow-x: hidden;
  /* background-color: #749fe4 !important;  */
}

.messages-header {
  font-size: larger;
  color: var(--fz-gray-1);
  margin-right: -25px;
}

.message-from {
  float: left;
  font-weight: bold;
  padding: 2px;
}
.message-time {
  float: right;
  color: var(--fz-gray-4);
  padding: 2px;
}
.message-text {
  float: inline-start;
  width: 100%;
  text-align: left;
  padding: 2px;
}

.add-message {
  float: right;
  margin-right: 5px;
  margin-top: -3px;
  cursor: pointer;
  font-size: 35px !important;
  color: var(--fz-green-menu);
}
.add-message:hover {
  cursor: pointer;
  font-size: 35px !important;
  color: var(--fz-green-3);
}

/* ------------------ */

.date-picker {
  border-radius: 0.25rem;
  /* border-color: var(--fz-gray-4); */
  border-color: #99999930;
  background-color: var(--fz-white-1);
  min-height: 38px;
  padding: 5px;
  text-align: center;
  width: 150px !important;
  font-weight: bold;
}

/* ------------------ */

.wrapper {
  display: flex !important; 
}
.column {
  width: 50% !important; 
}
.column--shrunk {
  /* prevents vertical stretching */  
  align-self: start !important; 
}


.div-style-1 {
  background-color: pink;
  height: 98%;
}
.div-style-3 {
  background-color: plum;
  height: 98%;
}
.div-style-2 {
  background-color: yellowgreen;
  width: calc(100vw);
  height: calc(10vh) !important; 
  align-self: start !important; 
}

.message-scroll-area-2 {
  height: calc(27vh);
  overflow-y: scroll;
  overflow-x: hidden;
  /* background-color: #749fe4 !important;  */
}



.label-style-1 {
  font-size: x-large;
  color: var(--fz-gray-3);
}
.label-style-2 {
  font-size: medium;
  color: var(--fz-gray-3);
}

.table-1 {
  display: grid;
  background-color: cyan !important;
  border: 10px solid #ff0000;
  border-collapse: separate;
  width: 100%;
  height: 100%;
}
.row-fill {
width: 100%;
}

.child-div {
  /* OR */
  flex-grow: 0 !important; /* Set only flex-grow to 0 */
}

.border-right {
  border-right: 3px solid var(--fz-gray-6);
}

/* ------------------ */
.pg-select {
  width: 180px;
  float: left;
  padding-right: 15px;
}
.pg-size-select {
  width: 100px;
  float: left;
}

/* ------------------ */

.notification-toast {
  background-color: var(--fz-green-5);
  padding: 5px;
  cursor: pointer;
}
.toast-line1 {
  color: var(--fz-gray-1);
}
.toast-line2 {
  color: var(--fz-gray-2);
}
.toast-line3 {
  color: var(--fz-gray-2);
}

/* ------------------ */

:root {
  --fz-gray-1: #434343 !important;
  --fz-gray-2: #666666 !important;
  --fz-gray-3: #999999 !important;
  --fz-gray-4: #b7b7b7 !important;
  --fz-gray-5: #cccccc !important;
  --fz-gray-6: #d9d9d9 !important;
  --fz-gray-7: #efefef !important;
  --fz-gray-8: #f3f3f3 !important;
  --fz-gray-9: #f9f9f9 !important;

  --fz-green-1: #133328 !important;
  --fz-green-2: #225c40 !important;
  --fz-green-3: #30825a !important;
  --fz-green-4: #3a9c6c !important;
  --fz-green-5: #9DE3B3 !important;
  --fz-green-menu: #889893 !important;

  --fz-white-1: #fcfcfc !important;
  --fz-white-2: #ffffff !important;

  --fz-black-1: #000000 !important;
  --fz-black-2: #000000 !important;

  --fz-alert-yellow: #d4af37;
  --fz-warning-red: #9c372a;
  --fz-text-dark: #333333;
  --fz-text-light: #ffffff;

  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

.td-5pct { width: 5%; }
.td-10pct { width: 10%; }
.td-15pct { width: 15%; }
.td-20pct { width: 20%; }
.td-25pct { width: 25%; }
.td-30pct { width: 30%; }
.td-40pct { width: 40%; }
.td-50pct { width: 50%; }
.td-60pct { width: 60%; }
.td-70pct { width: 70%; }
.td-80pct { width: 80%; }
.td-90pct { width: 90%; }
.td-100pct { width: 100%; }

.tr-ht-40pct { height: 40% !important; }
.tr-ht-50pct { height: 50% !important; }
.tr-ht-60pct { height: 60% !important; }
.tr-ht-100pct { height: 100% !important; }

/* Custom Colors */
.fz-gray-1 { color: var(--fz-gray-1) !important; }
.fz-gray-2 { color: var(--fz-gray-2) !important; }
.fz-gray-3 { color: var(--fz-gray-3) !important; }
.fz-gray-4 { color: var(--fz-gray-4) !important; }
.fz-gray-5 { color: var(--fz-gray-5) !important; }
.fz-gray-6 { color: var(--fz-gray-6) !important; }
.fz-gray-7 { color: var(--fz-gray-7) !important; }
.fz-gray-8 { color: var(--fz-gray-8) !important; }

.fz-gray-bg-1 { background-color: var(--fz-gray-1) !important; }
.fz-gray-bg-2 { background-color: var(--fz-gray-2) !important; }
.fz-gray-bg-3 { background-color: var(--fz-gray-3) !important; }
.fz-gray-bg-4 { background-color: var(--fz-gray-4) !important; }
.fz-gray-bg-5 { background-color: var(--fz-gray-5) !important; }
.fz-gray-bg-6 { background-color: var(--fz-gray-6) !important; }
.fz-gray-bg-7 { background-color: var(--fz-gray-7) !important; }
.fz-gray-bg-8 { background-color: var(--fz-gray-8) !important; }

.fz-green-1 { color: var(--fz-green-1) !important; }
.fz-green-2 { color: var(--fz-green-2) !important; }
.fz-green-3 { color: var(--fz-green-3) !important; }
.fz-green-4 { color: var(--fz-green-4) !important; }
.fz-green-5 { color: var(--fz-green-5) !important; }

.fz-green-bg-1 { background-color: var(--fz-green-1) !important; }
.fz-green-bg-2 { background-color: var(--fz-green-2) !important; }
.fz-green-bg-3 { background-color: var(--fz-green-3) !important; }
.fz-green-bg-4 { background-color: var(--fz-green-4) !important; }
.fz-green-bg-5 { background-color: var(--fz-green-5) !important; }

.fz-white-1 { color: var(--fz-white-1) !important; }
.fz-white-bg-1 { background-color: var(--fz-white-1) !important; }



/* .fz-status-yellow { background-color: var(--fz-alert-yellow) !important; } */
/* .fz-status-red { background-color: var(--fz-warning-red) !important; } */
/* .fz-status-green { background-color: var(--fz-green-4) !important; } */


.cursor-pointer {
  cursor: pointer;
}

.page-padding {
  padding: 5px;
  margin: 5px;
}

.pdfViewer {
  width: 100%;
  min-width: 50vw;
  max-width: 70vw;
  height: 100%;
  min-height: 90vh;
  max-height: 90vh;
  cursor: move;
  cursor: -moz-grabbing;
}

.pdfViewer2 {
  padding: 10px;
  height: 100%;
  background-color: #fafaf2;
  cursor: grab;
  overflow: auto;
}

/* ------------------------------ */
/* From previous version          */
/* ------------------------------ */

.hidden-element {
  display: none !important;
}

.static-form-value {
  background-color: #ebebeb;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 5px 10px 5px 10px;
  width: 100%;
  min-height: 38px;
  color: var(--fz-gray-2);
}

.workflowListItem {
  padding: 0px;
  margin: 0px;
}
.workflowListItem:hover {
  cursor: pointer;
}

.card-header-bg {
  background-color: #f5f5f5 !important;
}

.card-header-bg-overdue {
  background-color: #ffe9ed !important;
}

.card-header-bg-complete {
  background-color: #d3f2e3 !important;
}

.card-header-dash {
  padding: 5px;
  margin: -20px -25px;
}

.card-header-text {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--fz-gray-2) !important;
}

.statusOverdue {
  color: #ff0000 !important;
  /* border-bottom: 0px solid #000000 !important; */
}

.section-text {
  font-size: larger;  
  height: 35px;
  margin-top: -8px;
}


.task-item-padding {
  padding: 10px 0px !important;
  border-bottom-width: 1px;
  border-color: #efefef;
}